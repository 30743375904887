import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Homepage from './components/Homepage';
import Login from './components/Login';
import Game from './components/Game';
import Merch from './components/Merch';
import About from './components/About';
import Info from './components/Info';
import Cryptex from './components/Cryptex';
import Completion from './components/Completion';
import Quiz from './components/Quiz';
import ScrollableCryptex from './components/ScrollableCryptex';
import { NavProvider } from './context/NavContext';


function App() {
  return (
    <NavProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Homepage />} />
            <Route path="/game" element={<Game />} />
            <Route path="/merch" element={<Merch />} />
            <Route path="/about" element={<About />} />
            <Route path="/info" element={<Info />} />
            <Route path="/cryptex" element={<Cryptex />} />
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/scrollable" element={<ScrollableCryptex />} />
            <Route path="/completion" element={<Completion />} />
            
          </Routes>
        </Layout>
      </Router>
    </NavProvider>

    
  );
}

export default App;
