import React, { useEffect } from 'react';
import Phaser from 'phaser';
import GameScene from '../components/GameScene';
import '../assets/styles/Game.css';

const Game = () => {
    useEffect(() => {
        const config = {
            type: Phaser.AUTO,
            width: window.innerWidth,
            height: window.innerHeight,
            scene: GameScene,
            scale: {
                mode: Phaser.Scale.RESIZE,
                autoCenter: Phaser.Scale.CENTER_BOTH
            }
        };

        const game = new Phaser.Game(config);

        const resize = () => {
            game.scale.resize(window.innerWidth, window.innerHeight);
            game.scene.scenes.forEach(scene => scene.resize());
        };

        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
            game.destroy(true);
        };
    }, []);

    return <div id="game-container"></div>;
};

export default Game;