import React from 'react';

const Merch = () => {
  return (
    <div>
      <h1>Merchandise Page</h1>
      <p>Welcome to our merchandise page! Here you'll find all sorts of items related to our games.</p>
    </div>
  );
};

export default Merch;
