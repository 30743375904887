import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/Completion.css';



const Completion = () => {
    const navigate = useNavigate();

    const handleRestart = () => {
        // Logic to restart the game
        navigate('/quiz');
    };

    const handleHome = () => {
        navigate('/about');
    };

    return (
        <div className="completion-container">
            <h1>Congratulations! You've completed the game!</h1>

            <button onClick={handleRestart}>Restart Game</button>
            <button onClick={handleHome}>Return to Homepage</button>
        </div>
    );
};

export default Completion;
