import React, { useRef, useState, useEffect } from 'react';

const ScrollableCryptex = ({ onValidate }) => {
    const dials = ['0123456789', '0123456789', '0123456789', '0123456789']; // Four dials with numbers 0-9
    const refs = useRef(dials.map(() => React.createRef()));
    const [selectedNumbers, setSelectedNumbers] = useState(Array(dials.length).fill('0'));
    const [numberIndexes, setNumberIndexes] = useState(Array(dials.length).fill(0));

    const handleScroll = (index) => {
        const element = refs.current[index].current;
        const snapPoint = Math.round((element.scrollTop + element.clientHeight / 2) / 30) * 30;
        element.scrollTop = snapPoint - element.clientHeight / 2;

        const numberIndex = Math.round(element.scrollTop / 30);
        const newSelectedNumbers = [...selectedNumbers];
        newSelectedNumbers[index] = dials[index][numberIndex];
        setSelectedNumbers(newSelectedNumbers);

        const newNumberIndexes = [...numberIndexes];
        newNumberIndexes[index] = numberIndex;
        setNumberIndexes(newNumberIndexes);
    };

    const handleSubmit = () => {
        const finalAnswer = selectedNumbers.join('');
        console.log("Final Answer Submitted:", finalAnswer); // Debugging output
        if (onValidate) {
            onValidate(finalAnswer); // Trigger validation or further action passed from parent
        }
    };

    useEffect(() => {
        setSelectedNumbers(dials.map((dial, index) => dial[0]));
        setNumberIndexes(dials.map((dial) => 0));
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <div style={{ display: 'flex', height: '100px', overflow: 'hidden' }}>
                {dials.map((column, idx) => (
                    <div
                        key={idx}
                        ref={refs.current[idx]}
                        onScroll={() => handleScroll(idx)}
                        style={{
                            height: '110px',
                            width: '50px',
                            overflowY: 'auto',
                            border: '1px solid black',
                            position: 'relative',
                            backgroundColor: 'black'
                        }}
                    >
                        <div style={{ paddingTop: '60px', paddingBottom: '60px', color: 'white' }}>
                            {column.split('').map((item, index) => (
                                <div key={index} style={{
                                    height: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: index === numberIndexes[idx] ? '#696969' : 'transparent',
                                    color: 'white',
                                    fontSize: index === numberIndexes[idx] ? '26px' : '16px',
                                    fontFamily: "'Komika Hand', cursive"
                                }}>
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <button onClick={handleSubmit} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '14px' }}>
                Submit Answer
            </button>
            <div style={{ color: 'black', fontFamily: "'Komika Hand', cursive", textAlign: 'center' }}>
                Selected Numbers: {selectedNumbers.join(' ')}
            </div>
        </div>
    );
};

export default ScrollableCryptex;
