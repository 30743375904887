import Phaser from 'phaser';

class GameScene extends Phaser.Scene {
    constructor() {
        super({ key: 'GameScene' });
        this.selectedCell = null;
        this.selectedNumber = null;
        this.cells = []; // Keep track of cell text objects
        this.gridSize = 9;
    }

    preload() {
        // No assets to load for this example
    }

    create() {
        this.createGrid();
        this.createNumberSelection();
        this.scale.on('resize', this.resize, this);
    }

    resize() {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const cellSize = Math.min(screenWidth, screenHeight) / (this.gridSize + 3); // Adjusting to leave space for number selection

        this.cameras.main.setViewport(0, 0, screenWidth, screenHeight);
        this.createGrid();
        this.createNumberSelection();
    }

    createGrid() {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const cellSize = Math.min(screenWidth, screenHeight) / (this.gridSize + 3); // Adjusting to leave space for number selection

        if (this.graphics) {
            this.graphics.clear();
        } else {
            this.graphics = this.add.graphics();
        }

        this.graphics.lineStyle(2, 0xffffff, 1);

        const offsetX = (screenWidth - this.gridSize * cellSize) / 2;
        const offsetY = (screenHeight - this.gridSize * cellSize) / 4;

        // Create the grid
        for (let row = 0; row < this.gridSize; row++) {
            for (let col = 0; col < this.gridSize; col++) {
                const x = offsetX + col * cellSize;
                const y = offsetY + row * cellSize;

                this.graphics.strokeRect(x, y, cellSize, cellSize);

                const number = this.add.text(x + cellSize / 2, y + cellSize / 2, '', {
                    fontSize: `${cellSize * 0.5}px`,
                    color: '#ffffff',
                    fontFamily: 'Arial'
                }).setOrigin(0.5);

                const cell = {
                    row,
                    col,
                    x,
                    y,
                    number,
                    rect: this.add.rectangle(x + cellSize / 2, y + cellSize / 2, cellSize, cellSize).setStrokeStyle(2, 0xffffff, 0).setInteractive()
                };

                cell.rect.on('pointerdown', () => this.handleCellClick(cell));

                this.cells.push(cell);
            }
        }
    }

    createNumberSelection() {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const cellSize = Math.min(screenWidth, screenHeight) / (this.gridSize + 3);
        const offsetX = (screenWidth - 9 * cellSize) / 2;
        const offsetY = screenHeight - 1.5 * cellSize;

        for (let i = 1; i <= 9; i++) {
            const x = offsetX + (i - 1) * cellSize;
            const y = offsetY;

            const numberButton = this.add.text(x + cellSize / 2, y + cellSize / 2, i, {
                fontSize: `${cellSize * 0.5}px`,
                color: '#ffffff',
                backgroundColor: '#0000ff',
                fontFamily: 'Arial'
            }).setOrigin(0.5).setInteractive().on('pointerdown', () => this.handleNumberClick(i));

            numberButton.setStyle({
                padding: {
                    left: 10,
                    right: 10,
                    top: 5,
                    bottom: 5
                },
                borderRadius: '5px'
            });

            numberButton.on('pointerover', () => {
                numberButton.setStyle({ backgroundColor: '#0000cc' });
            });

            numberButton.on('pointerout', () => {
                numberButton.setStyle({ backgroundColor: '#0000ff' });
            });
        }
    }

    handleCellClick(cell) {
        if (this.selectedCell) {
            this.selectedCell.rect.setStrokeStyle(2, 0xffffff, 0);
        }

        this.selectedCell = cell;
        cell.rect.setStrokeStyle(2, 0x00ff00, 1);

        if (this.selectedNumber !== null) {
            cell.number.setText(this.selectedNumber);
        }
    }

    handleNumberClick(number) {
        this.selectedNumber = number;

        if (this.selectedCell) {
            this.selectedCell.number.setText(number);
        }
    }
}

export default GameScene;
