import React from 'react';

const Info = () => {
  return (
    <div>
      <h1>Info Page</h1>
      <p>Welcome to our info page! Here you'll find all information you need related to our games.</p>
    </div>
  );
};

export default Info;
