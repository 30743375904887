import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import banner from '../assets/images/emptyBanner.jpg';
import '../assets/styles/Homepage.css';
//image files
import PingPongTable from '../assets/images/icons/Ping-Pong-Table.jpg';
import RiverSteps from '../assets/images/icons/River-Steps.jpg';
import WaterFountain from '../assets/images/icons/water-fountain-icon.jpg';
import Skull from '../assets/images/icons/Skull-and-Crossbones.png';
import Ship from '../assets/images/icons/Pirate-Bay-Ship.jpg';
import Post06 from '../assets/images/icons/Post.jpg';

const quizData = [
    { 
        id: 1, 
        question: "What relative of Hugh Taylor commissioned his fountain?", 
        answer: "daughter", 
        hint: "", 
        nextId: 2,
        image: WaterFountain
    },
    { 
        id: 2, 
        question: "Since which century has the church stood here?", 
        answer: "twelfth", 
        hint: "Look under the table for the next clue.", 
        nextId: 3,
        image: Skull
    },
    { 
        id: 3, 
        question: "What is Kali doing under the Ping-Pong table?", 
        answer: "yawn", 
        hint: "Check what Kali is sitting on for another clue.", 
        nextId: 4,
        image: PingPongTable
    },
    { 
        id: 4, 
        question: "What is Kali sitting on?", 
        answer: "suitcase", 
        hint: "Go to the ship.", 
        nextId: 5,
        image: RiverSteps
    },
    { 
        id: 5, 
        question: "Name of Ship?", 
        answer: "kompan", 
        hint: "PPark", 
        nextId: 6,
        image: Ship
    },
    { 
        id: 6, 
        question: "Nearly how many millions were spent on the Pepys Parks?", 
        answer: "three", 
        hint: "Congratulations, You Finished the Game!", 
        nextId: null,
        image: Post06
    }
];

const Homepage = () => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answer, setAnswer] = useState('');
    const navigate = useNavigate();
    const currentQuestion = quizData[currentQuestionIndex];

    const handleSubmit = () => {
        if (answer.toLowerCase() === currentQuestion.answer.toLowerCase()) {
            if (currentQuestion.nextId) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
                setAnswer('');
            } else {
                navigate('/completion');
            }
        } else {
            alert('Incorrect answer, please try again.');
        }
    };

    return (
        <div className="container">
            <div className="keyhole-container">
                <img src={banner} alt="Keyhole Layout" className="keyhole-background" />
                <div className="quiz-content">
                    <h1>{currentQuestion.question}</h1>
                    <input
                        type="text"
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                        placeholder="Type answer in the box"
                        className="answer-input"
                    />
                    <button onClick={handleSubmit} className="submit-button">Submit</button>
                    {currentQuestion.hint && <p className="hint-text">hint: {currentQuestion.hint}</p>}
                </div>
            <div className="image-container">
                <img src={currentQuestion.image} alt="Relevant descriptive text" />
            </div>
            </div>
        </div>
    );
};

export default Homepage;
