import React from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';

const Cryptex = () => {
  const unityContext = useUnityContext({
    loaderUrl: "/Cryptex/Build/Build.loader.js",
    dataUrl: "/Cryptex/Build/Build.data",
    frameworkUrl: "/Cryptex/Build/Build.framework.js",
    codeUrl: "/Cryptex/Build/Build.wasm",
  });

  return (
    <div>
      <h1>Cryptex Game</h1>
      <Unity unityContext={unityContext} />
    </div>
  );
};

export default Cryptex;
